<template>
  <div data-theme="treasurebox" class="tw-bg-neutral/[.03] tw-w-full tw-h-full">
    <AppLayout>
      <template #rightTop>
        <NuxtLink to="/me" class="tw-flex tw-items-center tw-text-neutral/40">
          <span class="tw-hidden lg:tw-inline">{{ email }}</span>
          <SvgoUserIcon class="tw-w-8 tw-h-8 tw-ml-2" />
        </NuxtLink>
      </template>
      <UIQuery :pending="isPending" :error="error">
        <slot />
      </UIQuery>
    </AppLayout>
  </div>
</template>
<script setup lang="ts">
const { isPending, error,data } = useUserInfo(true);
const email = computed(() => data.value?.email);
</script>
